import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { TrackJS } from 'trackjs';

import { LocalStorage } from '../local-storage/local-storage';
import { MetricsService } from '../metrics/metrics.service';
import { DeviceDetectorService } from './device-detector.service';
import { devicePlatformFactory } from './device-platform.factory';
import { AllowedDevicePlatforms, AllowedDevicePlatformsType, IDevicePlatform } from './device-platform.interface';

const dduidKey = makeStateKey('DDUID');

@Injectable()
export class DeviceKS {
  name: string;
  type: AllowedDevicePlatforms;
  devicePlatform: IDevicePlatform | undefined;
  DUID = new BehaviorSubject<string | undefined>(undefined);
  public device: any;
  private forceDeviceInit = false;

  get currentDUID() {
    // return this.DUID.value || this.localStorage.getItem('TVDUID'); // TODO: localStorege.get podría devolver una Promise<any>
    return this.DUID.value;
  }

  get isCastDevice() {
    return this.type === 'chromecast';
  }

  constructor(
    private localStorage: LocalStorage,
    private metrics: MetricsService,
    private state: TransferState,
    private deviceDetector: DeviceDetectorService
  ) {
    this.DUID.next(this.state.get(dduidKey, undefined as any));
  }

  async init(force?: AllowedDevicePlatforms): Promise<string | null> {
    const currentDevicePlatformDetected = this.deviceDetector.detectDeviceType();

    if (!!AllowedDevicePlatformsType[force]) {
      this.forceDeviceInit = true;
      this.metrics.reset();
    }

    console.log(
      'currentDevicePlatformDetected',
      currentDevicePlatformDetected,
      AllowedDevicePlatformsType[force] ? `but forced to init as: ${force}` : ''
    );

    await this.processDeviceType(AllowedDevicePlatformsType[force] || currentDevicePlatformDetected);

    console.log('currentDevicePlatformSetted', this.devicePlatform.type);
    await this.setDUID(this.devicePlatform.deviceUid);

    return currentDevicePlatformDetected;
  }

  setDevice() {
    this.device = this.device || document.getElementById('device') || {};
  }

  async serialNumberWebosAndNetcast() {
    if (this.device.serialNumber) {
      if (!this.currentDUID) {
        await this.setDUID(this.device.serialNumber);
      }
    }
  }

  async processDeviceType(devicePlatform: AllowedDevicePlatforms): Promise<void> {
    this.setDevice();

    // comprobar si es tizen
    if (devicePlatform === AllowedDevicePlatformsType.tizen) {
      this.metrics.sendEvent('posibleTizen');
      await this.setDeviceType(AllowedDevicePlatformsType.tizen);
      this.devicePlatform.onMediaKeys();
      this.device = this.devicePlatform.deviceObject;
      this.setDUID(this.devicePlatform.deviceUid);
      return;
    }

    if (devicePlatform === AllowedDevicePlatformsType.movistar) {
      await this.setDeviceType(AllowedDevicePlatformsType.movistar);
      return;
    }

    if (devicePlatform === AllowedDevicePlatformsType.vizio) {
      await this.setDeviceType(AllowedDevicePlatformsType.vizio);
      return;
    }

    if (devicePlatform === AllowedDevicePlatformsType.webos) {
      await this.setDeviceType(AllowedDevicePlatformsType.webos);
      await this.serialNumberWebosAndNetcast();
      return;
    }

    if (devicePlatform === AllowedDevicePlatformsType.netcast) {
      await this.setDeviceType(AllowedDevicePlatformsType.netcast);
      await this.serialNumberWebosAndNetcast();
      return;
    }

    if (devicePlatform === AllowedDevicePlatformsType.zeasn) {
      await this.setDeviceType(AllowedDevicePlatformsType.zeasn);
      this.device = this.devicePlatform.deviceObject;
      this.metrics.register(this.devicePlatform.deviceObject);
      return;
    }

    await this.setDeviceType(AllowedDevicePlatformsType.basic);
    return;
  }

  async setDUID(hash: string) {
    this.state.set(dduidKey, hash as any);
    await this.localStorage.setItem('TVDUID', hash);
    TrackJS.addMetadata('TVDUID', hash);
    this.DUID.next(hash);
  }

  registerDeviceDataOnMetrics() {
    if (!!this.devicePlatform.deviceData) {
      this.metrics.register(this.devicePlatform.deviceData);
    }
    this.setDevice();
    this.devicePlatform.loadDeviceParameters();
    this.metrics.register(this.devicePlatform.deviceMixPanelData);
  }

  async setDeviceType(type: AllowedDevicePlatforms) {
    this.name = type;
    this.type = type;
    try {
      this.devicePlatform = await devicePlatformFactory(type, this.forceDeviceInit);
      TrackJS.addMetadata('platform', this.devicePlatform?.type);
      // this.metrics.sendEvent('setDeviceTypeOK', { deviceType: type, deviceID: this.devicePlatform.deviceUid });
    } catch (e) {
      this.metrics.sendEvent('errorOnSetDeviceType', {
        message: (e as any).message,
        deviceType: type,
        deviceID: this.devicePlatform.deviceUid,
      });
      this.devicePlatform = await devicePlatformFactory('basic', false);
    }
    this.registerDeviceDataOnMetrics();
  }
}
