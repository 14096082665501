import { Injectable } from '@angular/core';
import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';

import { changeToMobileEventName, changeToMobilePropsName } from './changeEventNameAndProps';

@Injectable()
export class CustomerIoService {
  enabled = true;
  ready = false;
  analytics: AnalyticsBrowser;
  private writeKey = '00cc26ef82d3a5064bba';

  constructor() {
    if (!this.ready) {
      console.log('CustomerIOService Constructor');
      this.analytics = this.load();
    }
  }

  load() {
    // eslint-disable-next-line curly
    if (!this.enabled) return null;
    this.ready = true;
    return AnalyticsBrowser.load(
      { writeKey: this.writeKey },
      {
        initialPageview: false,
        disableClientPersistence: true,
      }
    );
  }

  async sendEvent(eventName: string, eventData?: Record<string, any>) {
    // eslint-disable-next-line curly
    if (!this.enabled) return;
    await this.analytics.track(changeToMobileEventName(eventName), {
      ...changeToMobilePropsName(eventName, eventData),
    });
  }

  async identify(data: Record<string, any>, key: string) {
    // eslint-disable-next-line curly
    if (!this.enabled) return;
    await this.analytics.identify(key, {
      ...changeToMobilePropsName('identify', data),
      email: data.Email,
    });
  }

  async reset() {
    // eslint-disable-next-line curly
    if (!this.enabled) return;
    await this.analytics.reset();
  }
}
